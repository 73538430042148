@import '../../variables.scss';

$prefix: 'takeover';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
    overflow: auto;
  }

  &-container {
    width: 100%;
    &-info {
      background-color: $common-back-color;
      padding: 1rem 3rem;
      color: #888;
      display: flex;
      justify-content: space-around;
      font-size: $font-size-xl;
      text-align: left;
      &>div {
        flex: 1;
      }
      .strong-line {
        color: $active-color;
        padding: 0 0.2rem;
        font-size: $font-size-xll;
        font-weight: bold;
      }
      .font-color-orange {
        color: $plocation-nonstop;
      }
    }
    .info-list{
      padding: 1rem;
      padding-top: 0;
    }
    .list-download{
      padding:1rem 1rem 1rem 0;
      text-align: right;
    }
    .info-second {
      padding-top: 0;
    }
    .info-first {
      padding-bottom: 0;
    }
    &-charts {
      height: 24rem;
      width: 100%;
      margin-top: 1.2rem;
      display: flex;
      
      &-takeover {
        height: 100%;
        background-color: $common-back-color;
        flex: 1;
      }
      &-work {
        height: 100%;
        background-color: $common-back-color;
        //border-left: 1px solid #f4f4f4;
        flex: 1;
        position: relative;
      }
    }
    
    &-region {
      width: 100%;
      padding-left: 1rem;
      // margin-top: 1rem;
      background-color: $common-back-color;
      &-select {
        width: 15rem;
        padding-right: 1rem;
      }
      &-title {
        font-weight: bold;
        font-size: 1.2rem;
        display: inline-block;
        padding: 1.2rem 0  0 1.2rem;
      }
      .tool-item {
        margin: 1.2rem 0 0 1.5rem;
      }
    }

  }
}
