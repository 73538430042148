@import '../../variables.scss';

$prefix: 'users';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
    overflow: auto;
  }

  &-inner {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    h3 {
      margin-bottom: 1rem;
    }
  }

  &-form {
    &-divider {
      font-size: $font-size;
    }

    &-section {
      padding-left: 2rem;
    }

    &-delete {
      margin-left: 1rem;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &-actions {
    display: flex;

    &-btn {
      margin-right: 0.3rem;
    }
  }

  &-trips {
    padding: 1rem;
    margin: 0;

    &-item {
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: $border-radius;
      background-color: $component-background-color;
      list-style: none;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    &-location {
      display: flex;
      padding: 0.5rem 0;

      &-time {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        padding-right: 1rem;
      }

      &-timetable {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 1rem;
        text-align: right;
      }

      &-names {
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &-timeline-wrapper {
        padding: 16px 0;
      }

      &-timeline {
        position: relative;
        height: 40px;
        // padding: 16px 0;
        border-left: 2px dotted #ccc;

        // Start point
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          bottom: 105%;
          right: -3px;
          height: 8px;
          width: 8px;
          border-width: 2px;
          border-style: solid;
          border-color: $border-color;
          border-radius: 50%;
        }

        // Destination point
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 105%;
          right: -3px;
          height: 8px;
          width: 8px;
          border-width: 2px;
          border-style: solid;
          border-color: $primary-color;
          border-radius: 50%;
        }
      }
    }
  }
}
