@import '../../variables.scss';

$prefix: 'hover';

.#{$prefix} {
  & {
    display: inline-block;
    padding-bottom: 3px;
    border-bottom: 1px dotted $primary-color;
    cursor: pointer;
  }

  &-prefix {
    margin-right: 5px;
    font-weight: bold;
    user-select: none;
  }

  &-content {
    display: inline-block;
    cursor: pointer;
  }
}
