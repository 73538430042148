$prefix: 'progress';

.#{$prefix} {
  &-box {
    line-height: 20px;
    .ant-row{
      margin:5px auto
    }
    .ant-progress{
      line-height: 0.5;
    }
  }
  &-icon {
    margin-right: 0.5rem;
  }
  &-upload {
    cursor: pointer;
    color: #16A085;
  } 
}
