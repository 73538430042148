@import '../../variables.scss';

$prefix: 'scenario';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
    overflow: auto;
  }
  &-title {
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #16A085;
    color: #fff;
  }
  &-list-table{
    background: #fff;
    padding:10px;
    .ant-table-placeholder{
      border-bottom: 1px solid #e8e8e8;
    }
    th{
      background: #fafafa !important;
    }
    .ant-table-bordered .ant-table-tbody > tr > td{
      padding:10px
    }
  }
  &-list-tree{
    background: #fafafa;
    padding:10px;
    margin-right: 10px;
    min-height: 600px;
    overflow-y: auto;
    
  }
  &-container {
    width: 100%;
    &-scenario {
      padding:0px;
      background: #fff;
      .ant-table-placeholder{
        border-bottom: 1px solid #e8e8e8;
      }
    }
    &-info {
      background-color: $common-back-color;
      padding: 1rem 3rem;
      color: #888;
      display: flex;
      justify-content: space-around;
      font-size: $font-size-xl;
      text-align: left;
      &>div {
        flex: 1;
      }
      .strong-line {
        color: $active-color;
        padding: 0 0.2rem;
        font-size: $font-size-xll;
        font-weight: bold;
      }
      .font-color-orange {
        color: $plocation-nonstop;
      }
    }
    .ant-tabs-bar{
      margin-bottom:0px;
    }
    .info-list-scenario{
      margin: 1rem;
      th{
        background: #f8f8f8;
        font-weight: bold;
      }
      .ant-table-tbody tr:last-child{
        color: #ff0000;
        background: #f8f8f8;
        td:first-child{
          font-weight: bold;
        }
        
      }
      .ant-table-tbody tr{
        td{
          padding:10px
        }
        td:last-child{
          color: #ff0000;
        }
        td:first-child{
          font-weight: bold;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
    
    .info-descript {
      margin:1rem;
      margin-right:0px;
      th{
        font-weight: bold;
      }
      td{
        color: #ff0000;
      }
      th,td{
        padding:11px;
      }
    }
    .descript-detail{
      margin-right: 1rem;
      .ant-descriptions-title{
        margin-bottom: 10px;
        margin-top: 20px;
        color: #16A085;
      }
      td{
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .list-download{
      padding:1rem 1rem 1rem 0;
      text-align: right;
    }
    .info-second {
      padding-top: 0;
    }
    .info-first {
      padding-bottom: 0;
    }
    &-charts {
      width: 100%;
      display: flex;
      margin-top: 0px;
      &-takeover {
        height: 100%;
        background-color: $common-back-color;
        flex: 1;
      }
      &-work {
        height: 100%;
        background-color: $common-back-color;
        //border-left: 1px solid #f4f4f4;
        flex: 1;
        position: relative;
      }
    }
    
    &-region {
      //width: 100%;
      padding-left: 1rem;
      // margin-top: 1rem;
      background-color: $common-back-color;
      &-select {
        width: 12rem;
        padding-right: 1rem;
      }
      &-title {
        font-weight: bold;
        font-size: 1.2rem;
        display: inline-block;
        padding: 1.2rem 0  0 1.2rem;
      }
      .tool-item {
        margin: 1.2rem 0 0 0.5rem;
      }
    }

  }
}
