@import '../../variables.scss';

$prefix: 'invitations';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    h3 {
      margin-bottom: 1rem;
    }

    &-actions {
      padding-right: 1rem;
    }
  }

  &-list {
    flex: 1;
    min-height: 0;
    overflow: scroll;

    &-panel {
      padding: 0 1rem;
    }
  }

  &-code {
    font-weight: bold;
    font-size: $font-size-l;
    letter-spacing: 0.2rem;

    &.valid {
      color: $primary-color;
    }
  }
}
