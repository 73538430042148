@import '../../variables.scss';

$prefix: 'tags';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
    overflow: auto;
  }

  &-panel {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;

      h3 {
        margin-bottom: 1rem;
      }
    }
  }

  &-form {
    &-divider {
      font-size: $font-size;
    }

    &-section {
      padding-left: 2rem;
    }

    &-delete {
      margin-left: 1rem;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &-actions {
    display: flex;

    &-btn {
      margin-right: 0.3rem;
    }
  }
}
