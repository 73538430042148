@import '../../variables.scss';

$prefix: 'trips';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
    overflow: auto;
  }

  &-inner {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    h3 {
      margin-bottom: 1rem;
    }
  }

  &-list {
    &-download {
      color: $text-color;
      text-decoration: underline;
    }
  }

  &-filter {
    &-dropdown {
      padding: 0.5rem;
    }

    &-checkboxes {
      padding-bottom: 0.5rem;
    }
  }
}
