@import '../../variables.scss';

$prefix: 'login';

.#{$prefix} {
  & {
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: $component-body-color;
  }

  &-left {
    width: 40vw;

    @include media('<=s') {
      width: 100vw;
    }

    @include media('<=m', '>s') {
      width: 70vw;
    }

    @include media('<=l', '>m') {
      width: 50vw;
    }
  }

  &-panel {
    padding: 25vh 5rem;

    @include media('<=s') {
      padding: 25vh 2rem;
    }

    @include media('<=m', '>s') {
      padding: 25vh 3rem;
    }

    @include media('<=l', '>m') {
      padding: 25vh 4rem;
    }
  }

  &-right {
    flex: 1;
    background-image: url('../../_assets/bk.png');
    background-size: cover;
    overflow: hidden;
  }

  &-row {
    &:last-child {
      margin-top: 0.5rem;
    }
  }

  &-message {
    padding-left: 1.8rem;
    min-height: 1.7rem;
    font-size: 0;
    color: $tip-color;
    user-select: none;

    span {
      font-size: $font-size-xs;
      vertical-align: middle;
      line-height: 1.7rem;
    }
  }

  &-lang-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: #a0a0a0;
    padding: 1.5rem 0;
    text-align: right;
  }

  &-text-logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: $text-color;
    user-select: none;
  }
}
