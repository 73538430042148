@import '../../variables.scss';

$prefix: 'resources';

.#{$prefix} {
  & {
    padding: $page-padding;
  }

  &-panel {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;

      h2 {
        margin-bottom: 1rem;
      }
    }

    &-content {
      padding: 0 1rem;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: auto auto 1fr;
    justify-content: flex-start;
    gap: 1rem 3rem;

    &-section {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 4;
      margin: 0.8rem 0 0;
      padding-left: 1rem;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 0.5rem;
        height: 0.5rem;
        transform: translateY(-50%);
        background-color: $primary-color;
        border-radius: 1px;
      }
    }

    &-name {
      grid-column-start: 1;
      grid-column-end: 2;
      margin: 0 0 0 1rem;
    }

    &-link {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}
