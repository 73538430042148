@import './variables.scss';

html {
  font-size: 14px;
}

// Self-defined antd extra styles
.ant-tabpane-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/*** Antd override styles ***/
// Table
.layout .ant-table-placeholder {
  border-bottom: none;
}

// Tabs
.ant-tabs-nav-wrap {
  background-color: $component-background-color;
}

.ant-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ant-tabs-content {
  display: flex;
  flex: 1;
  min-height: 0;
}

.ant-tabs-tabpane {
  height: 100%;
  overflow: hidden;
}

// InputNumber
.ant-modal-body .ant-input-number {
  width: 100%;
}

// Drawer
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
}

// Menu
#root .ant-menu-inline .ant-menu-item,
#root .ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
