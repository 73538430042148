@import '../../variables.scss';

$prefix: 'empty-card';

.#{$prefix} {
  & {
    width: 100%;
    max-height: 100%;
    padding: 1rem 0;
    border-radius: $border-radius;
    background-color: $component-background-color;
    color: $border-color;
    text-align: center;

    &.dark {
      color: #bbb;
      background-color: $component-body-color;
    }

    &-icon {
      font-size: 3rem;
      // filter: drop-shadow(1px 1px 1px $shadow-color-light);
    }

    &-content {
      margin: 0.3rem 0 0;
      user-select: none;
    }
  }
}
