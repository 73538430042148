@import '../../variables.scss';

$prefix: 'dashboard';

.#{$prefix} {
  & {
    padding: $page-padding;
  }

  &-panel {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;

      h3 {
        margin-bottom: 1rem;
      }
    }

    &-content {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      padding: 0.5rem 1rem;
    }
  }

  &-stats {
    display: flex;
    padding: 0;
    margin: 0;

    &-block {
      display: flex;
      flex-direction: column;

      h4 {
        margin-right: 2rem;
        // font-size: $font-size-l;
      }
    }

    &-item {
      list-style-type: none;
      margin-right: 3rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
