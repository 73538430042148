@import '../../variables.scss';

$prefix: 'pers';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
    overflow: auto;
  }

  &-inner {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;
    margin-bottom: $page-padding;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-search {
    display: inline-flex;
    align-items: flex-end;
  }

  &-list {
    &-actions {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}
.columnscResiz .react-resizable {
  position: relative;
  background-clip: padding-box;
}

.columnscResiz .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}
