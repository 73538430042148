@import '../../variables.scss';

$prefix: 'target';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
    overflow: auto;
  }

  &-inner {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;
    margin-bottom: $page-padding;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-search {
    display: inline-flex;
    align-items: flex-end;
  }

  &-list {
    &-actions {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}
