@import '../../variables.scss';

$prefix: 'layout';

.#{$prefix} {
  & {
    height: 100vh;
    width: 100vw;
    background-color: $component-body-color;
  }

  &-nav {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-user {
    padding: 0.5rem 1.5rem;
    font-weight: bold;
  }

  &-setting {
    display: flex;
    justify-content: space-around;
    padding: 0.8rem;

    &-item {
      flex: 1;
      margin: 0.5rem 0;
      text-align: center;
    }

    &-btn {
      margin: 0;
      padding: 0 0.3rem;
      border: none;
      font-size: $font-size;
      outline: none;
      transition: 0.3s;
      cursor: pointer;

      &:hover, &:focus {
        color: $primary-color;
      }
    }
  }
}
