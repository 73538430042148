@import '../../variables.scss';

$prefix: 'lang';

.#{$prefix} {
  & {
    user-select: none;
  }

  &-btn {
    padding: 2px 7px;
    border: none;
    outline: none;
    background-color: transparent;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
