@import '../../../../variables.scss';

$prefix: 'tag';

.#{$prefix} {
  & {
    padding: 2px 5px;
    border-radius: $border-radius;
    background-color: $primary-color;
    color: $inverse-text-color;
    font-size: $font-size-s;
    transition: 0.2s;
  }
}
