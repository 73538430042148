@import '../../variables.scss';

$prefix: 'vmaps';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
    overflow: auto;
  }
  &-line {
    background: #fff;
    margin-top: 10px;
  }
  &-detail{
    padding: 10px 0 0 30px;
  }
  &-detail-title{
    text-align: center;
    padding-top: 10px;
    span{
      font-weight: bold;
    }
  }
  &-map-container{
    height: 590px;
  }
  &-inner {
    border-radius: $border-radius;
    padding:0.5rem;
    background:rgba($color: #fff, $alpha: 0.5 );
    position: relative;
    z-index: 2;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &-search {
    display: inline-flex;
    align-items: flex-end;
  }

  &-list {
    &-actions {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}
.columnscResiz .react-resizable {
  position: relative;
  background-clip: padding-box;
}

.columnscResiz .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.amap-logo{
  display: none;
  opacity: 0 !important;
}
.amap-marker-label{
  border-color:#ccc
}
