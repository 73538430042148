@import '../../variables.scss';

$prefix: 'v-events';

.#{$prefix} {
  & {
    padding: $page-padding;
    height: 100%;
    overflow: auto;
  }

  &-inner {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-search {
    display: inline-flex;
    align-items: flex-end;
  }

  &-list {
    &-actions {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  &-form {
    &-divider {
      font-size: $font-size;
    }

    &-section {
      padding-left: 2rem;
    }
  }

  &-content {
    line-height: 2.4;
    font-size: 13px;
  
    .item {
      display: flex;
  
      & > div {
        &:first-child {
          width: 200px;
        }
      }
    }
  }  
}
