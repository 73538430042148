// vendors
@import '~include-media/dist/_include-media.scss';

// colors
$primary-color: #16a085;
$text-color: #202020;
$inverse-text-color: #ffffff;
$component-background-color: #ffffff;
$component-body-color: #f4f4f4;
$body-color: #ffffff;
$tip-color: #999999;
$tip-color-light: #d0d0d0;
$shadow-color: #cccccc;
$shadow-color-light: #eee;
$border-color: #dddddd;
$danger-color: #e74c3c;
$active-color: #5dade2;
$inactive-color: #5d6d7e;

$plocation-active: #45b39d;
$plocation-inactive: $inactive-color;
$plocation-nonstop: #f5b041;
$plocation-deprecated: #cd6155;

$path-active: $active-color;
$path-inactive: $inactive-color;

$vehicle-available: $active-color;
$vehicle-inuse: #48c9b0;
$vehicle-inactive: $inactive-color;

$user-active: $active-color;
$user-inactive: $inactive-color;

$path-line: #5499c7;
$district-circle: #5499c7;
$track-line: #000000;

$common-back-color: #fff;

// border
$border-radius: 4px;

// padding
$page-padding: 1.5rem;

// font-size
$font-size-xs: 0.7rem;
$font-size-s: 0.8rem;
$font-size: 1rem;
$font-size-l: 1.1rem;
$font-size-xl: 1.5rem;
$font-size-xll: 2rem;

// break points: overide default breakpoints of 'include-media'
$breakpoints: (
  's': 420px,
  'm': 768px,
  'l': 1024px,
);

// box shadow
$box-shadow-default: 1px 0px 6px 0px $shadow-color;

// button base style
@mixin button-base {
  display: inline-block;
  margin: 0 1px;
  padding: 3px;
  border: 1px solid transparent;
  border-radius: $border-radius;
  outline: none;
  background-color: transparent;
  text-align: center;
  line-height: 100%;
  vertical-align: 10%;
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  transition: all 0.2s;
}

:export {
  primaryColor: $primary-color;
  bodyColor: $body-color;

  plocationACTIVE: $plocation-active;
  plocationINACTIVE: $plocation-inactive;
  plocationNONSTOP: $plocation-nonstop;
  plocationDEPRECATED: $plocation-deprecated;

  pathACTIVE: $path-active;
  pathINACTIVE: $path-inactive;

  vehicleAVAILABLE: $vehicle-available;
  vehicleINUSE: $vehicle-inuse;
  vehicleINACTIVE: $vehicle-inactive;

  userACTIVE: $user-active;
  userINACTIVE: $user-inactive;

  pathLine: $path-line;
  districtCircle: $district-circle;
  trackLine: $track-line;
}
