@import '../../variables.scss';

$prefix: 'plocations';

.#{$prefix} {
  & {
    padding: $page-padding;
    overflow: auto;
  }

  &-inner {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    h3 {
      margin-bottom: 1rem;
    }
  }

  &-form {
    &-divider {
      font-size: $font-size;
    }

    &-section {
      padding-left: 2rem;
    }

    &-row {
      display: flex;
    }
  }
}
