@import '../../variables.scss';

$prefix: 'monitor';

.#{$prefix} {
  & {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &-tools {
    padding: 0.5rem;
  }

  &-display {
    flex: 1;
    display: flex;
    min-height: 0;
  }

  &-map {
    position: relative;
    flex: 1;
    min-width: 0;

    $map-border: 1px solid $border-color;

    &-container {
      height: 100%;
      width: 100%;
    }

    & .amap-ui-pathsimplifier-container &-title {
      padding: 5px 10px;
      border: none;
      border-radius: 2px;
      background-color: #fff;
      color: $text-color;
      font-size: $font-size-s;
      box-shadow: $box-shadow-default;

      &::before {
        border-right: none;
      }
    }

    & .amap-info-outer {
      padding: 10px 25px 10px 10px;
    }

    &-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      user-select: none;
    }

    &-status {
      position: absolute;
      top: 1.2rem;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: 0.2s;
      user-select: none;

      &.visible {
        opacity: 0.6;
      }

      &-content {
        margin-left: 0.3rem;
      }
    }

    &-districts {
      position: absolute;
      top: 1.2rem;
      right: 1rem;
      min-width: 11rem;
      box-shadow: $box-shadow-default;
      border-radius: $border-radius;
      border: $map-border;
    }

    &-locating {
      position: absolute;
      top: 40%;
      right: 1rem;
      display: flex;
      flex-direction: column;
      background-color: $component-background-color;
      box-shadow: $box-shadow-default;
      border-radius: $border-radius;
      border: $map-border;
      transform: translateY(-50%);

      button {
        position: relative;
        padding: 0.8rem 0.5rem;

        &:not(:last-of-type)::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 20%;
          right: 20%;
          border-bottom: 1px solid $border-color;
        }
      }
    }

    &-settings {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
    }
  }

  &-control {
    display: flex;
    flex-direction: column;
    width: 340px;
    background-color: $component-body-color;

    &-tools {
      display: flex;
      padding-bottom: 0.7rem;

      &-btn {
        margin-left: 0.5rem;
      }
    }
  }

  &-list {
    flex: 1;
    padding: 0 1rem 0.5rem;
    margin: 0;
    list-style-type: none;
    overflow: scroll;
    scroll-behavior: smooth;

    &-item {
      display: flex;
      position: relative;
      margin: 0.7rem 0;
      padding: 0.6rem 0 0.6rem;
      border-width: 2px;
      border-style: solid;
      border-radius: $border-radius;
      border-color: $component-background-color;
      background-color: $component-background-color;
      transition: 0.2s;

      &.masked {
        user-select: none;
        pointer-events: none;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0);
        transition: 0.3s;
        pointer-events: none;
      }

      &.masked::after {
        background-color: rgba(255, 255, 255, 0.6);
      }

      &:hover {
        box-shadow: $box-shadow-default;
      }

      &.active {
        border-color: $border-color;
        box-shadow: $box-shadow-default;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &-content {
      flex: 1;
      margin: 0 0.8rem;
    }

    &-actions {
      position: relative;
      // padding-left: 0.2rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        // width: 1px;
        // background-color: #f2f2f2;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 0.3rem;
    }

    &-title {
      flex: 1;
      position: relative;
      margin: 0;
      font-size: $font-size-l;
      font-weight: bold;
      transition: 0.2s;

      .location & {
        font-size: $font-size;
      }
    }

    &-liveness {
      display: flex;
      align-items: center;
      font-weight: normal;

      span:not(:last-of-type) {
        margin-right: 0.2rem;
      }
    }

    &-subtitle {
      font-size: $font-size-s;
      font-weight: normal;
      margin-left: 0.5rem;
      color: $tip-color;
    }

    &-action {
      margin: 0;
      padding: 0 0.3rem;
      border: none;
      outline: none;
      font-size: $font-size;
      transition: 0.3s;
      cursor: pointer;

      &.active,
      &:hover {
        color: $primary-color;
      }
    }

    &-block {
      margin: 0.5rem 0 1rem;
      padding: 0;

      .location & {
        margin: 0.5rem 1rem;
      }
    }

    &-stats {
      min-width: 4rem;
      display: inline-block;
      margin-right: 0.5rem;

      &-title {
        margin-bottom: 0.2rem;
        font-size: $font-size-s;
        font-weight: normal;
        color: $tip-color;
      }

      &-figure {
        font-size: $font-size;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: $font-size-s;
      color: $tip-color;
    }

    &-points {
      padding-left: 1.8rem;
    }

    &-point {
      position: relative;
      color: $text-color;
      list-style: none;
      padding: 0.4rem 0;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -1rem;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        transform: translateY(-50%);
        background-color: currentColor;
      }

      &-name {
        color: $text-color;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.icon-circle {
  display: block;
  height: 15px;
  width: 15px;
  border: 4px solid $district-circle;
  border-radius: 50%;
}
