@import '../../../../variables.scss';

$prefix: 'map-button';

.#{$prefix} {
  & {
    @include button-base;

    font-size: 1.1rem;
  }
}
