@import '../../variables.scss';

$prefix: 'paths';

.#{$prefix} {
  & {
    padding: $page-padding;
    overflow: auto;
  }

  &-inner {
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $component-background-color;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    h3 {
      margin-bottom: 1rem;
    }
  }

  &-list {
    &-stops {
      margin: 0;
      padding: 0;
      display: grid;
      gap: 0.5rem;
      counter-reset: stop;
    }

    &-stop {
      position: relative;
      list-style-type: none;

      &::before {
        counter-increment: stop;
        // content: '#'counter(stop);
        content: counter(stop)'.';
        position: absolute;
        left: -1.5rem;
      }
    }
  }

  &-form {
    &-stops {
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $primary-color;
        font-weight: bold;
      }

      &-delete {
        cursor: pointer;
        color: $tip-color-light;
        font-size: 1.3rem;
        transition: 0.3s;

        &:hover {
          color: $danger-color;
        }
      }
    }
  }
}
