@import '../../variables.scss';

$prefix: 'search';

.#{$prefix} {
    &-tab {
        height: auto;
        overflow: scroll;
        display: block;
        scroll-behavior: smooth;
    }
    &-bar {
        padding: 50px;
    }

    &-advanced-form {
        padding: 50px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        .ant-form-item {
            display: inline-block;
        }
        .ant-for-item-control-wrapper {
            flex: 1;
        }
    }

    &-result-card {
        margin-bottom: 20px;
    }

    &-result-list {
        padding: 50px;
        background: rgb(239, 255, 255);;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
    }

    &-collapse {
        background: #fbfbfb;
        .ant-collapse-item {
            margin-bottom: 24px;
            overflow: hidden;
            .ant-collapse-header {
                padding: 12px 16px 12px 40px;
            }
            .ant-collapse-content {
                padding-left: 40px;
            }
        }
    }
}

.match-colon {
    vertical-align: -webkit-baseline-middle;
    display: inline;
}
